import React, {Fragment, useCallback, useMemo, useState} from "react";
import types from '../atoms/built-fields';
import CheckboxField from "@gotombola/react-ds/lib/atoms/fields/CheckboxField";
import clsx from "clsx";
import {buildFormFromDsn, cleanAllValue} from "../utils/form";

const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';

export function BuiltForm({onSubmit, defaultCountry, dsn, defaultDsn, onChange, defaultValue, t, description, text, mandatoryOptInMessage, buttonLabel, children}: BuiltFormProps) {
    const [value, setValue] = useState(defaultValue)
    const hasSaleMandatoryOptIn = !!mandatoryOptInMessage;
    const [saleOptIn, setSaleOptIn] = useState(false);
    const form = useMemo(() => buildFormFromDsn(dsn || defaultDsn), [dsn, defaultDsn]);
    const isButtonDisabled = !(form.submittable(value) && (!hasSaleMandatoryOptIn || saleOptIn));
    const handleCheckbox = useCallback((event: any) => {
        event?.stopPropagation?.();
        setSaleOptIn(!saleOptIn);
    }, [saleOptIn, setSaleOptIn]);

    const update = useCallback((data: any) => {
        onChange?.(data);
        setValue(data);
    }, [onChange, setValue]);

    const handleSubmit = useCallback((e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        onSubmit && onSubmit(cleanAllValue(value));
    }, [onSubmit, value]);

    return (
        <div className={'w-full space-y-4'}>
            {!!description && <div className={'font-go-text text-go-text w-full font-light text-center sm:text-justify'}>{description}</div>}
            {(form.content || []).map((c: any, index: number) => {
                const Comp = types[c.type || ''];
                return Comp ? <Comp key={index} onChange={update} values={value} t={t} country={defaultCountry} {...c} /> : <Fragment key={index}></Fragment>;
            })}
            {!!text && <div className={'font-go-text text-go-text w-full font-light text-center sm:text-justify'}>{text}</div>}
            {hasSaleMandatoryOptIn && (
                <CheckboxField onChange={handleCheckbox} defaultValue={saleOptIn} title={mandatoryOptInMessage} required />
            )}
            {children || false}
            <button
                type={"button"}
                disabled={isButtonDisabled}
                onClick={handleSubmit}
                className={clsx("font-go-button w-full p-3 border disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 rounded-md text-xl", !isButtonDisabled && colors)}
            >
                {buttonLabel}
            </button>
        </div>
    );
}

export interface BuiltFormProps {
    dsn?: string;
    defaultDsn?: string;
    description?: any;
    text?: any;
    mandatoryOptInMessage?: any;
    defaultValue: any;
    t: Function;
    onSubmit: Function;
    buttonLabel: any;
    onChange?: Function;
    children?: any;
    defaultCountry?: string;
}

export default BuiltForm;
