export function computeCustomBonuses(features: string|undefined, customBonuses: string|undefined, defaultValue: string) {
    const v = customBonuses || defaultValue;

    if (features?.includes('max_1_ticket_per_sale')) {
        return v.split(/\s*,\s*/).filter(x => {
            const [a, _ = undefined] = x.split(/\s*\|\s*/);
            return !(parseInt(a) > 0);
        }).join(',');
    }
    return v;
}

export default computeCustomBonuses;
