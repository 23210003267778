import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function PhoneBuiltField(props: PhoneBuiltFieldProps) {
    const t = props.t;
    return <TextBuildField name={'phone'} helper={t('field_phone_helper', {country: t([`country_${props.country?.toLowerCase()}`, props.country])}) } {...props} />
}

export interface PhoneBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default PhoneBuiltField;
