const paymentMeans = {
    stripe: {priority: 10, id: "stripe-card", label: 'game_buy_payment_page_stripe_option', contexts: ['sale', 'promise', 'bookpayment']},
    helloasso: {priority: 30, id: "helloasso", label: 'game_buy_payment_page_helloasso_option', contexts: ['sale', 'promise', 'bookpayment']},
    lydia: {priority: 40, id: "lydia", label: 'game_buy_payment_page_lydia_option', contexts: ['sale', 'promise', 'bookpayment']},
    paypal: {priority: 20, id: "paypal", label: 'game_buy_payment_page_paypal_option', contexts: ['sale', 'promise', 'bookpayment']},
    paypal_account: {priority: 50, id: "paypal", label: 'game_buy_payment_page_paypal_account_option', contexts: ['sale', 'promise', 'bookpayment']},
    promise: {priority: 60, id: "promise", label: 'game_buy_payment_page_pay_later_option', helper: 'game_buy_payment_page_pay_later_helper', ifAlone: 'game_buy_payment_page_pay_later_ifalone', contexts: ['sale', 'promise']},
    preorder: {priority: 70, id: "preorder", label: 'game_buy_payment_page_pre_order_option', helper: 'game_buy_payment_page_pre_order_helper', ifAlone: 'game_buy_payment_page_pre_order_ifalone', contexts: ['sale']},
    twint: {priority: 1, id: "twint", label: 'game_buy_payment_page_twint_option', helper: 'game_buy_payment_page_twint_helper', ifAlone: 'game_buy_payment_page_twint_helper', contexts: ['sale', 'promise', 'bookpayment']},
};

const paymentMeanCodes = Object.entries(paymentMeans).sort((a, b) => a[1].priority > b[1].priority ? b[1].priority : (a[1].priority < b[1].priority ? 1 : 0)).map(x => x[0]);

export function listPaymentMeans(type: string, mapper: (feature: string, mean: any) => any | null) {
    return paymentMeanCodes.map((pm: string) => paymentMeans[pm]?.contexts?.includes(type) && mapper(pm, paymentMeans[pm])).filter((item: any) => !!item);
}
